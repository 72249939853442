import React from 'react';
import Image from 'next/image';
import ImageFallback from '@components/common/Image/ImageFallback';
import counters from '@constants/homePageStats';
import { getCdnImageUrl } from '../../utils/cloudflare';
import SearchBar from '../common/SearchBar';

export default function HotelPartner() {
  const s3AssetsBaseUrl = process.env.NEXT_PUBLIC_S3_STATIC_ASSETS_LINK;
  const topWaveUrl = getCdnImageUrl(1920, '/home-v2/blue-wave-top.png', s3AssetsBaseUrl);
  const bottomWaveUrl = getCdnImageUrl(1920, '/home-v2/blue-wave-bottom.png', s3AssetsBaseUrl);
  const topImageUrl = getCdnImageUrl(
    300,
    '/home-v2/bottom-search/bottom-search-sub-image@2x.jpg',
    'https://static-assets.resortpass.com/assets',
    100,
  );
  const topImageUrlBlur = getCdnImageUrl(
    30,
    '/home-v2/bottom-search/bottom-search-sub-image@2x.jpg',
    'https://static-assets.resortpass.com/assets',
    10,
  );
  const bottomImageUrl = getCdnImageUrl(
    300,
    '/home-v2/bottom-search/bottom-search-main-image@2x.jpg',
    'https://static-assets.resortpass.com/assets',
    100,
  );
  const bottomImageUrlBlur = getCdnImageUrl(
    30,
    '/home-v2/bottom-search/bottom-search-main-image@2x.jpg',
    'https://static-assets.resortpass.com/assets',
    10,
  );

  const counterTextStyle = 'text-rp-primary-black-medium font-rp-cera-thin';

  return (
    <div className="w-full" data-testid="counter-search-section">
      <div className="relative h-20">
        <Image
          src={topWaveUrl}
          layout="fill"
          objectFit="cover"
          alt="Spacer: This image acts as a visual limiter or spacer in the layout"
        />
      </div>
      <div className="bg-rp-secondary-blue pb-16">
        <div className="mx-auto max-w-8xl px-6 max-w">
          <div className="flex justify-between items-center">
            <div className="hidden d:w-2/5 d:pt-10 d:pb-16 d:block">
              <div className="relative h-48 w-52 rounded-lg mb-6 shadow-search">
                <ImageFallback
                  src={topImageUrl}
                  blurDataUrl={topImageUrlBlur}
                  layout="fill"
                  objectFit="contain"
                  className="rounded-lg"
                  alt="Pool image #1"
                />
              </div>
              <div className="relative h-72 w-72 rounded-lg -mt-44 ml-32 shadow-search">
                <ImageFallback
                  src={bottomImageUrl}
                  blurDataUrl={bottomImageUrlBlur}
                  layout="fill"
                  objectFit="contain"
                  className="rounded-lg"
                  alt="Pool image #2"
                />
              </div>
            </div>
            <div className="w-full pt-10 text-3xl tracking-tight font-rp-cera-medium text-rp-primary d:w-2/5 d:text-4xl">
              {counters.map((counter) => (
                <div role="heading" aria-level={2} key={counter.label} className="mb-1">
                  {counter.count}
                  <span className={counterTextStyle}>{` ${counter.label}`}</span>
                </div>
              ))}
              <div>
                <span className={counterTextStyle}>... and counting</span>
              </div>
              <div className="mt-20 text-base">
                <SearchBar hideCalendar isBottomSearchBar />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-20">
        <Image
          src={bottomWaveUrl}
          layout="fill"
          objectFit="cover"
          alt="Spacer: This image acts as a visual limiter or spacer in the layout"
        />
      </div>
    </div>
  );
}
