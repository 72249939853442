import React from 'react';
import styles from './SearchSpinner.module.css';

export default function SearchSpinner() {
  return (
    <div className={`${styles['lds-ring']}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
