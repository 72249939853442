import React, { useEffect, useState } from 'react';
import Image, { StaticImageData } from 'next/image';
import brokenImagePlaceholder from './BrokenImagePlaceholder';
import loadingImagePlaceholder from './LoadingPlaceholderImage';

type ImageFallbackProps = {
  src: string | StaticImageData;
  [x: string]: any;
  fallbackSrc?: string | null;
  alt: string;
  loading?: 'lazy' | 'eager';
  priority?: boolean;
  blurDataUrl?: string | undefined;
};

const defaultProps = {
  fallbackSrc: null,
  loading: 'lazy',
  priority: false,
  blurDataUrl: undefined,
};

export default function ImageFallback({
  src,
  fallbackSrc,
  alt,
  loading,
  priority,
  blurDataUrl,
  ...rest
}: ImageFallbackProps) {
  const [imgSrc, setImgSrc] = useState(src);
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const fadedImageProperty = process.env.NEXT_PUBLIC_SHOW_FADED_LOADING_IMAGE;
  const isFadedLoadingImageEnabled = fadedImageProperty ? fadedImageProperty === 'true' : false;

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const updateImageSrc = () => {
    if (fallbackSrc) {
      setImgSrc(fallbackSrc);
    } else {
      setImgSrc(brokenImagePlaceholder);
    }
  };

  const getClasses = () => {
    if (isFadedLoadingImageEnabled) {
      return `${rest.className}`;
    }
    return `${rest.className} ${imageIsLoaded ? 'lazyloaded' : 'lazyloading'}`;
  };

  return (
    <Image
      {...rest}
      alt={alt}
      src={imgSrc}
      placeholder={isFadedLoadingImageEnabled ? 'blur' : undefined}
      blurDataURL={isFadedLoadingImageEnabled ? blurDataUrl || loadingImagePlaceholder : ''}
      onLoadingComplete={(result) => {
        setImageIsLoaded(true);
        if (result.naturalWidth === 0) {
          // Image is broken
          updateImageSrc();
        }
      }}
      onError={updateImageSrc}
      loading={loading}
      priority={priority}
      onClick={rest.onClick ? rest.onClick : () => null}
      className={getClasses()}
    />
  );
}

ImageFallback.defaultProps = defaultProps;
